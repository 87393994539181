.Reports .notes h4 {
    margin: 10px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Reports .notes p {
    color: #666;
}