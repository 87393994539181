.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}

.late {
  color: rgb(124, 57, 57);
  font-size: 12px;
  align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 12px;
}

.info {
  max-width: 200px;
  margin: 6px auto 0;
  text-align: center;
  font-size: 12px;
}
