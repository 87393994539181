.selectors {
    background-color: #b4b7c7;
    opacity: 1;
    transition: 0.2s;
}

.selectors:hover {
    background-color: #8492c9;
    opacity: 1;
}
