.App {
    margin-top: 15px;
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: 15px;
}

.nav-item {
    margin-left: 5px;
}

.App .navbar-brand {
    font-weight: bold;
}